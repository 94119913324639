import $ from 'jquery'
import { defer } from 'lodash-es'

window.js_dom_ready = false

const Turbolinks = window.Turbolinks
const _errs = window._errs // Not sure what this is

// Trigger page:change event when the first page has finished loading
$(document).ready(function () {
  defer(() => {
    window.debug('page ready')
    window.pageLoadHandler()
    if (!Turbolinks && !window.Turbo) {
      window.pageChangeHandler()
    }
  })
})

// Convenience methods
const onPageLoadCallbacks = []
export const onPageLoad = (name, callback) =>
  onPageLoadCallbacks.push([name, callback])
window.onPageLoad = onPageLoad

const onPageChangeCallbacks = []
export const onPageChange = (name, callback) =>
  onPageChangeCallbacks.push([name, callback])
window.onPageChange = onPageChange

let domChangedCallbacks = []
let domChangedCallbacksNeedSorting = false
window.onDomChanged = function (selector, ...args) {
  const callback = args[args.length - 1]
  const priority = args.length > 1 ? args[0] : 100
  domChangedCallbacks.push([selector, callback, priority])
  domChangedCallbacksNeedSorting = true
}

window.log = function (...args) {
  if (console?.log?.apply != null) {
    console.log.apply(console, args)
  } else if (console?.log != null) {
    console.log(args[0])
  } else if (window.location.host.match(/\.(dev|test)$/)) {
    alert(args[0])
  }
}

if (window.location.search.match(/(\?|&)d=1(&|$)/)) {
  window.debug = (...args) => window.log.apply(null, args)
} else {
  window.debug = () => {
    /* nothing */
  }
}

window.logerror = function (...args) {
  try {
    window.log.apply(window.logerror, args)
  } catch (error) {
    console.log('logerror', error)
  }
}

window.logSource = (name) => {
  try {
    throw new Error(name)
  } catch (error) {
    console.log(name, $.isReady, error)
  }
}

// Backwards compat hack for $.browser

$.browser = {
  mozilla:
    /mozilla/.test(navigator.userAgent.toLowerCase()) &&
    !/webkit/.test(navigator.userAgent.toLowerCase()),
  webkit: /webkit/.test(navigator.userAgent.toLowerCase()),
  opera: /opera/.test(navigator.userAgent.toLowerCase()),
  msie: /msie/.test(navigator.userAgent.toLowerCase()),
  ipad: /ipad/.test(navigator.userAgent.toLowerCase()),
  ios: /ipad|iphone|ipod/.test(navigator.userAgent.toLowerCase()),
  mobile: /android|webos|iphone|ipad|ipod|blackberry/.test(
    navigator.userAgent.toLowerCase()
  ),
}

// Trigger the contents changed event
$.fn.changed = function () {
  return this.each(function () {
    window.debug(`changed: ${this.id}`)
    const $this = $(this)

    if (domChangedCallbacksNeedSorting) {
      domChangedCallbacks = domChangedCallbacks.sort((a, b) => {
        const aPriority = a[2] || 100
        const bPriority = b[2] || 100
        return aPriority - bPriority
      })
      domChangedCallbacksNeedSorting = false
    }

    domChangedCallbacks.forEach(([selector, callback]) => {
      if (callback) {
        window.debug(
          `    Invoking onDomChanged callback for ${selector} on ${$this}`
        )
        if ($this.is(selector)) {
          callback.call($this)
        }
        $this.find(selector).each(function () {
          return callback.call($(this))
        })
      }
    })
  })
}

window.pageFetchHandler = function () {
  window.debug(`page:fetch - ${window.location.href}`)
  window.js_dom_ready = false
  $('body').css('cursor', 'wait')
}

// Set classes .no-touch or .touch, so we can distinguish between touch devices in CSS
// Hover effects are, for example, not very effective on touch devices
window.onload = function () {
  const klass =
    'ontouchstart' in document.documentElement ? 'touch' : 'no-touch'
  document.documentElement.className += ' ' + klass
}

window.pageChangeHandler = function () {
  window.debug(`page:change - ${window.location.href}`)

  const body = $('body')
  body.css('cursor', 'auto')

  if (_errs) {
    $.extend(_errs.meta, {
      trail: '',
      username: body.data('username'),
      request_id: body.data('request-id'),
    })
  }

  const redirectTo = body.data('redirect-to')
  if (redirectTo) {
    defer(() => (window.location.href = redirectTo))
  }

  const highlight = $('#' + body.data('highlight'))
  const scroll = $('#' + body.data('scrollto'))
  const animatedScroll = $('#' + body.data('animated-scrollto'))

  if (highlight[0]) {
    defer(function () {
      highlight.scrollIntoView({ duration: 0 })
      highlight.effect('highlight', {}, 10000)
    })
  } else if (scroll[0]) {
    defer(() => scroll.scrollIntoView({ duration: 0 }))
  } else if (animatedScroll[0]) {
    defer(() => animatedScroll.scrollIntoView())
  }

  const tab = body.data('tab')
  if (tab) {
    $(`a[data-toggle=tab][href=#tab-${tab}]`).click()
  }

  const ajax = body.data('ajax')
  if (ajax) {
    $.sajax({
      url: ajax.url,
      type: ajax.method,
      data: ajax.data,
    })
  }

  window.debug('invoking onPageChange callbacks')
  onPageChangeCallbacks.forEach(([name, callback]) => {
    try {
      window.debug(`    invoking onPageChange callback ${name}`)
      callback.call()
    } catch (error) {
      window.logerror(error, {
        message: "    error in 'onPageChange' callback",
        callback: name,
      })
    }
  })

  $('body').changed()

  const WebFontConfig = window.WebFontConfig
  if (!WebFontConfig || (!WebFontConfig.google && !WebFontConfig.typekit)) {
    $(document).trigger('fonts-loaded')
  }

  window.js_dom_ready = true
}

window.pageLoadHandler = function () {
  window.debug(`page:load - ${window.location.href} - invoking callbacks`)
  onPageLoadCallbacks.forEach(([name, callback]) => {
    try {
      window.debug(`  Invoking onPageLoad callback ${name}`)
      callback.call()
    } catch (error) {
      window.log('  -- caught error')
      window.logerror(error, {
        message: `    error in 'onPageLoad' callback ${name}`,
        callback: name,
      })
    }
  })
}

$(document).on('page:fetch', window.pageFetchHandler)
$(document).on('page:change', window.pageChangeHandler)
$(document).on('page:load', window.pageLoadHandler)

// Helpers for idempotent setup

window.onAndNow = function (events, selector, callback) {
  const namespaced_events = events
    .split(/[ ]+/)
    .map((e) => `${e}.simplero`)
    .join(' ')
  $(document)
    .off(namespaced_events, selector)
    .on(namespaced_events, selector, callback)
  window.onPageLoad(`Callback for ${events} on ${selector}`, function () {
    if ($(selector)[0]) {
      callback.apply($(selector))
    }
  })
}

// Simplero namespace.

window.Simplero = {
  state: {
    isAjaxUpdating: false,
  },
}

// SimpleroManagers

export class SimpleroManager {
  constructor(elm, name) {
    this.elm = $(elm)
    this.manager_name = name
    this.elm.data(`simpleroManager${this.manager_name}`, this)
  }
}

window.SimpleroManager = SimpleroManager

$.fn.setupManager = function (ManagerClass, name, options) {
  this.each(function () {
    const hasManager = $(this).data(`simpleroManager${name}`)
    if (!hasManager) new ManagerClass(this, name, options)
  })
}

export const registerManager = function (manager, name, selector, options) {
  window.debug(`registerManager ${name} for ${selector}`)
  window.onDomChanged(selector, function () {
    window.debug(`Setting up manager ${name} for ${this.id}`)
    $(this).setupManager(manager, name, options)
  })
}
window.registerManager = registerManager

// Disable default drag & drop support
// https://github.com/blueimp/jQuery-File-Upload/wiki/Options#dropzone

$(document).bind('drop dragover', (e) => e.preventDefault())

$(document).on('fonts-loaded', function () {
  if ($.fn.bigtext) {
    $('[data-format=bigtext]').bigtext()
  }
})

// Get a WYSIWYG editor, whether TinyMCE or ProseMirror
// For ProseMirror, returns a ProseMirrorProxy object, which has methods to mimic TinyMCE API
// so you can use the same methods to interact with either.
window.getWysiwygEditor = function (id) {
  return window.proseMirror?.get(id) || $(`#${id}`).data('editor')
}
